import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SortDirection } from 'src/app/shared/enums/common.enum';
import { environment } from 'src/environments/environment';
import { ProductDetail } from '../interfaces/product-detail.interface';
import { ProductForm, ProductFormImage } from '../interfaces/product-form.interface';
import { Product, ProductRequestBody } from '../interfaces/product.interface';

const apiUrl = environment.apiUrl + 'merchant/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
  ) { }

  searchProducts(body: ProductRequestBody) {
    return this.http.post(apiUrl+'/search', {
      item_per_page: body.itemPerPage,
      current_page: body.currentPage,
      filters: body.filters,
      sorts: body.sorts ? body.sorts : [{ field: 'products.id', direction: SortDirection.desc }],
      category_id: body.categoryId,
      category_slug: body.categorySlug,
      brands: body.brands,
      min_price: body.minPrice,
      max_price: body.maxPrice,
      search_query: body.searchQuery,
      foundation_shade_colors: body.foundationShadeColors,
    }).pipe(map((res:any) => {
      let products: Product[] = res.data.products;
      return { totalProducts: res.data.total_items, products: products };
    })).pipe(catchError((error) => this.handleError(error)));
  }

  getProduct(slug: string) {
    return this.http.get(apiUrl+'/'+slug).pipe(map((res: any) => {
      return res.data[0] as ProductDetail;
    }));
  }

  updateActiveStatus(slug: string, isActive: boolean) {
    return this.http.put(`${apiUrl}/${slug}/active_status/update`, {is_active: isActive})
      .pipe(catchError((error) => this.handleError(error)));
  }

  addProduct(formValue: ProductForm) {
    return this.http.post(apiUrl, formValue).pipe(map((res:any) => {
      this.handleSuccess('Successfully add new product, beautybook admin will review and notify you when your product approved');
      return res.data;
    })).pipe(catchError((error) => this.handleError(error)));
  }

  uploadProductImage(productId: any, image: ProductFormImage, isNew: boolean = false) {
    var formData: FormData = new FormData();
    formData.append('product_id', productId);
    formData.append('sequence', image.sequence);
    formData.append('description', image.description);
    formData.append('is_active', image.is_active ? "1" : "0");
    formData.append('is_default', image.is_default ? "1" : "0");
    formData.append('image', image.image);
    formData.append('is_new_product', isNew ? "1": "0");

    return this.http.post(apiUrl+'_image', formData).pipe(map((res:any) => {
      return res.data;
    })).pipe(catchError((error) => this.handleImageError(error)));
  }

  uploadVariantImage(variantId: any, image: ProductFormImage, isNew: boolean = false) {
    var formData: FormData = new FormData();
    formData.append('variant_id', variantId);
    formData.append('sequence', image.sequence);
    formData.append('description', image.description);
    formData.append('is_active', image.is_active ? "1" : "0");
    formData.append('is_default', image.is_default ? "1" : "0");
    formData.append('image', image.image);
    formData.append('is_new_product', isNew ? "1": "0");

    return this.http.post(apiUrl+'_image', formData).pipe(map((res:any) => {
      return res.data;
    })).pipe(catchError((error) => this.handleImageError(error)));
  }

  massDeleteProductImage(requestBody: any) {
    return this.http.post(apiUrl+'_image/mass_delete', requestBody).pipe(map((res: any) => {
      return res.data;
    })).pipe(catchError((error) => this.handleImageError(error)));
  }

  massUpdateProduct(slug: string, formValue: ProductForm) {
    return this.http.put(`${apiUrl}/${slug}/mass_update`, formValue).pipe(map((res:any) => {
      this.handleSuccess('Product data updated');
      return res.data;
    })).pipe(catchError((error) => this.handleImageError(error)));
  }

  deleteProduct(slug: any) {
    return this.http.delete(apiUrl+'/'+slug).pipe(map((res) => {
      this.handleSuccess('Successfully delete product');
      return res;
    })).pipe(catchError((error) => this.handleImageError(error)));
  } 

  private handleSuccess(message: string) {
    this.toastr.success(message);
  }

  private handleError(error: any) {
    var errorMessage = 'Something went wrong, please try again';
    if(error && (error.message)) {
      errorMessage = error.message;
    }
    if(error && error.data && error.data.length > 1) {
      errorMessage = error.data[0];
    }
    return throwError(errorMessage);
  }

  private handleImageError(error: any) {
    var errorMessage = 'Something went wrong, please try again';
    if(error && (error.message)) {
      errorMessage = error.message;
    }
    if(error && error.data && error.data.length > 1) {
      errorMessage = error.data[0];
    }
    this.toastr.success(errorMessage);
    return throwError(errorMessage);

  }
}
